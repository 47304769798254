import React from "react"
import Buddha from "../images/buddha.png"

export default function Home() {
    return (
	<>
	    <>
 		<div style={{ backgroundColor: 'black' , display: 'grid' , placeItems: 'center' , height: '100vh' }}>
		    <div>
                        <img src={Buddha} alt="" />
		    </div>
	    	</div>
	    </>
	</>
    )
}

